let anot=require("./example/a.js")
window.anot=anot

let dcid=require('./pb.js').dcid

async function get_cid(cid,recv){
  try{
    const response=await fetch("https://cloudflare-ipfs.com/ipfs/"+cid)
    const r=await response.json()  
    console.log(r)
    recv(r,cid)
  }catch(err){
    make_oops_ui(cid)
  }
}

import * as Plot from "@observablehq/plot"
console.log(anot)
let data=anot.episodes.map((x)=>({
 Time:(x.t[0]+x.t[1])/2,
 Length:(x.t[1]-x.t[0]),
 Frequency:(x.f[0]+x.f[1])/2
}))
console.log(data);

function ignition(){
  let url=new URL(window.location.toString())
  let cid=url.searchParams.get("cid")
  if(!cid){
    return make_welcome_ui()
  }
  let kind=dcid(cid)
  if(!kind){
    return make_oops_ui(cid)
  }
  if(kind==="anot"){
    return get_cid(cid,make_annot_ui)
  }
  if(kind=="exp"){
    return get_cid(cid,make_exp_ui)
  }
  
}

function make_oops_ui(cid){
  document.title="Not found — Ultrasonic Vocalisation Repository"
  document.querySelector("main").innerHTML="<div id='oops'>"+
    "<h1>Resource cannot be found</h1>"+
    "CID '"+cid+"' is not whitelisted for the repository,"+
    " is not valid or couldn't be loaded."
    +"</div>"
}

function make_welcome_ui(){
  document.title="Ultrasonic Vocalisation Repository"
  
  document.querySelector("#tb").innerText="Experiment list"
  
 let main=document.querySelector("main")
 main.innerHTML='<ul><li>'+
  '<a href="?cid=QmbBWPeHn4nYGRtfnjwfeehfpx84hNbAF4BKvikB6NKGJW">'+
    'Recordings of rat alarm calls emitted and relied in social context'
    +'</a>'
    +'</li></ul>'
}


function make_annot_ui(anot,anot_cid){
 let main=document.querySelector("main")
 main.innerHTML='<div id="summary_table"></div><div id="scatterplot"></div> <div id="timeline"></div> '
  document.title=anot.name+" — annotated USV recording"
  let scat=document.querySelector("#scatterplot")
  let timl=document.querySelector("#timeline")
  let tab=document.querySelector("#summary_table")
  document.querySelector("#tb").innerText=anot.name+" — annotated USV recording"
  
  let data=anot.episodes.map((x)=>({
   Time:(x.t[0]+x.t[1])/2,
   Length:(x.t[1]-x.t[0]),
   Frequency:(x.f[0]+x.f[1])/2
  }))
  annot_scatter(data,scat)
  annot_timeline(data,timl)
  annot_table(anot,tab)
}

function make_exp_ui(exp,exp_cid){
 let main=document.querySelector("main")
 main.innerHTML='<div id="exp_table"></div>'
  document.title=exp.title+" — USV experiment"
  let exp_table=document.querySelector("#exp_table")
  document.querySelector("#tb").innerText=""
  let ac=exp.authors.map((x)=>{
    console.log(x)
    console.log(x.orcid)
    if(x.orcid){
      return('<a href="https://orcid.org/'+x.orcid+'">'+(x.name||'??')+'</a>')
    }else{
      return(x.name||'??')
    }
  }).join(', ')
  let al=Object.entries(exp.annotated_recordings).map(x=>{
    let cid=x[0]
    let v=x[1]
    let name=v.case||cid
    return('<li><a href="?cid='+cid+'">'+name+'</a></li>')
  }).join('')
  let rl=exp.citations.map(x=>{
    return('<li><a href="'+x.doi+'">'+x.text+'</a></li>')
  }).join('')
  exp_table.innerHTML='<h1>'+exp.title+'</h1>'+
   '<p class="authors">Contributors: '+ac+'</p>'+
   '<h2>Annotated recordings</h2>'+
   '<ul>'+al+'</ul>'+

   '<h2>References</h2>'+
   '<ul>'+rl+'</ul>'+
   '<h2>License</h2>'+exp.license
  
}


function annot_table(anot,div){
  div.innerHTML="<h2>Summary</h2>"+
  "<p>Annotation "+anot.name+" lists "+anot.episodes.length+" episodes.</p>"+
  "<p>Method: "+anot.method+"</p>"+
  "<p>Associated recording: "+anot.recording+"</p>"
}

function annot_scatter(data,div){
 const plot=Plot.plot({
  color:{legend:true,label:"Recording time [s]"},
  marks:[
   Plot.dot(data,{x:"Length",y:"Frequency",fill:"Time"}),
  // Plot.crosshair(data,{x:"Length",y:"Frequency"}),
   ],
   x:{type:"log",label:"Episode span [s]"},
   y:{label:"Episode mid frequency [Hz]"}
 })
 //const div = document.querySelector("#myplot")
 div.innerHTML="<h2>Episode scatterplot</h2>"
 div.append(plot)
}

function annot_timeline(data,div){
  const plot=Plot.plot({
  color:{legend:true,label:"Episode span [s]"},
   marks:[
    Plot.dot(data,{x:"Time",y:"Frequency",fill:"Length"}),

   ],
   x:{label:"Recording time [s]"},
   y:{label:"Episode mid frequency [Hz]"},
  width:document.body.clientWidth*0.8
  })
  div.innerHTML="<h2>Episode timeline</h2>"

  div.append(plot);
}

ignition()
