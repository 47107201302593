//Pseudo-backend, will be replaced by the real one caching relations from IPFS records


const known_anot_cids=new Set([
"QmRsZW5qwoPGAh9Z79Dos12SAyJam7e373f8sxp1xYXRTi",
"QmZyVBpkkdpyK2km9r4c9CSGpL7m9AEQRsMDy1GfAH2CvV",
"QmdUMUK9NeUpLktRhueQFnvXY3rDv7WHQymQwPbUYvYKB4",
"QmbXeXZSKiaMpB56vPanemwVy7iLKFLb3w1Tti9mEdkhF6",
"QmRxHZVJ5fzUyawgUoTSupdLHQGcGiUe5YxZnKumNKLN8H",
"QmdAhpArMZexjc3wDfoSEMxeACd6akdBpdZGaWcAxskyuD",
"QmfTtmvo1AnAo3cWZPgMp9p1KbJU4dthYjJzdRzH4iUxj6",
"QmaCufbgLVwTX1zKv6LafP4onC2nDvSY35yhfFGCvHm3M5",
"QmNtZirRefcSL1UtR383vBjUKAsgj1vZyVmh7LfraZ4qan",
"QmXtYo7KfcJWzuT9mvqfC8LWMB9sMo85C3La7JAt9DsKVM",
"QmNT61KWMptP22wbqXaJBHJA9THsWd9mvAFjhV4BipECqy",
"QmT8dgRhJfk83QDWwsQE9Xu1TmA8hmjNAKerC3nwZahitq",
"QmcKGeXqqSzKRqxrcUqEyUVAbJr1YitMJTjAgoyghC6PgG",
"QmNvtN4oZ91ma8xwKfkkQEdb18R8jwB9q5p8GxRJ9GkLkD",
"QmYdnrLQT5u33wWPamFK5zkJJrv9nzGff1j9dnLpxaTtMN",
"QmZLngGX6ntpeyhXmN7APebhfu8gZiQiVrLBXRyyvdj2DG",
"QmPYeYLQ36LuzpoNHXLPr2o2zrZPZmQ4zjMTZKsjzy2HGH",
"QmbyYup29YX4H1tWtTZ6ytXfqj7ypddnryWiL4RZtVumZW",
"QmY5CQhKrYgbDV889i7oW5WyvASYiyzAFTRhMM62v2PDvi",
"QmRULsuDRoWqqtijTKP7rprRgxNQuTpKVcYswE7H7Wzq76"])

const known_exp_cids=new Set([
"QmbBWPeHn4nYGRtfnjwfeehfpx84hNbAF4BKvikB6NKGJW"])

function dcid(cid){
  if(known_anot_cids.has(cid)){
    return('anot')
  }
  if(known_exp_cids.has(cid)){
    return('exp')
  }
}

module.exports.dcid=dcid

